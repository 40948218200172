<template>
  <div>
    <div class="inline">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Thêm cấu hình khách hàng'">
          <template v-slot:preview>
            <v-form ref="form" v-model="valid" lazy-validation>
              <div class="row">
                <b-form-group class="col-4">
                  <template>
                    <label>Tên: <span class="text-danger">*</span> </label>
                  </template>
                  <b-form-input
                    size="sm"
                    type="text"
                    v-model="name"
                    :state="validateState('name')"
                    required
                    placeholder="Nhập tên loại cấu hình"
                    class="mb-3"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-live-feedback">
                    Vui lòng nhập tên loại cấu hình
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
              <b-row>
                <b-col cols="4">
                  <b-form-group>
                    <template>
                      <label>Mô tả:</label>
                    </template>
                    <b-form-textarea
                      size="sm"
                      v-model="description"
                      :placeholder="'Thêm mô tả'"
                      :rows="6"
                      :max-rows="8"
                      :state="validateState('description')"
                    ></b-form-textarea>
                    <b-form-invalid-feedback id="input-live-feedback"
                      >Vui lòng nhập mô tả</b-form-invalid-feedback
                    >
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- <h4>Điều kiện</h4> -->
              <div class="separator separator-dashed my-5"></div>
              <b-row class="mb-2">
                <b-col cols="12">
                  <div class="d-flex align-items-center">
                    <h5 class="m-0">Điều kiện:</h5>
                    <b-button
                      class="ml-1"
                      style="fontweight: 600"
                      variant="primary"
                      size="sm"
                      type="submit"
                      @click="insertField"
                    >
                      <i
                        style="font-size: 1rem"
                        class="flaticon2-add-1 pr-0"
                      ></i>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="12">
                  <div>
                    <b-table
                      class="table-bordered table-hover col-md-12"
                      :fields="fields"
                      :items="settings"
                      v-if="renderComponent"
                    >
                      <template v-slot:cell(fieldCode)="row">
                        <!-- <b-input
                          type="text"
                          size="sm"
                          class="input-style text-right"
                          v-model="row.item.filedCode"
                          v-mask="mask"
                        ></b-input> -->
                        <treeselect
                          :options="listField"
                          :multiple="false"
                          size="sm"
                          placeholder="Chọn Loại Điều Kiện"
                          noResultsText="Không có kết quả"
                          :match-keys="['label']"
                          openDirection="bottom"
                          :clearable="false"
                          v-model="row.item.fieldCode"
                        >
                          <label
                            slot="option-label"
                            slot-scope="{ node, labelClassName }"
                            :class="labelClassName"
                          >
                            <span :title="node.label"> {{ node.label }}</span>
                          </label>
                        </treeselect>
                      </template>
                      <template v-slot:cell(operator)="row">
                        <treeselect
                          :options="listOperator"
                          :multiple="false"
                          size="sm"
                          placeholder="Chọn toán tử"
                          noResultsText="Không có kết quả"
                          :match-keys="['label']"
                          openDirection="bottom"
                          :clearable="false"
                          v-model="row.item.operator"
                        >
                          <label
                            slot="option-label"
                            slot-scope="{ node, labelClassName }"
                            :class="labelClassName"
                          >
                            <span :title="node.label"> {{ node.label }}</span>
                          </label>
                        </treeselect>
                      </template>
                      <template v-slot:cell(value)="row">
                        <b-input
                          v-if="
                            row.item.fieldCode !==
                              SEGMENT_FIELD_CODE.BILL_CREATE_DATE
                          "
                          type="text"
                          size="sm"
                          class="input-style"
                          v-model="row.item.value"
                          v-mask="mask"
                        ></b-input>
                        <b-input-group v-else>
                          <date-picker
                            placeholder="Từ ngày"
                            class="form-control form-control-sm"
                            :config="dpConfigs.date"
                            v-model="row.item.value"
                          ></date-picker>
                        </b-input-group>
                      </template>
                      <template v-slot:cell(actions)="row">
                        <v-icon
                          small
                          class="text-danger"
                          @click="deleteField(row.item)"
                          v-b-tooltip
                          title="Xóa"
                          >mdi-delete</v-icon
                        >
                      </template>
                    </b-table>
                  </div>
                </b-col>
                <b-col cols="6" md="4"></b-col>
              </b-row>
            </v-form>
          </template>
          <template v-slot:foot>
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              type="submit"
              @click="createSegment"
              >Lưu</b-button
            >
            <router-link :to="'/customer-segment'">
              <b-button
                style="margin-left: 10px; font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
                >Hủy</b-button
              >
            </router-link>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>
<style scoped>
.gender-appellation {
  display: flex;
  gap: 4px;
}

.center-label label {
  margin-bottom: 0px;
}

.form-group label {
  font-weight: 500;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
// import moment from 'moment';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { currencyMask } from './../../../utils/common';
import { SEGMENT_FIELD_CODE } from '@/utils/enum';

// Import datePicker component
// import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import { v4 } from 'uuid';
import moment from 'moment';

// import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});
import { xoa_dau } from '../../../utils/common';

export default {
  mixins: [validationMixin],
  data() {
    return {
      SEGMENT_FIELD_CODE,
      settings: [],
      renderComponent: true,
      currency: currencyMask,
      listField: [{ id: '', label: 'Chọn loại điều kiện' }],
      listOperator: [{ id: '', label: 'Chọn loại toán tử' }],
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      mask: currencyMask,
      isNew: true,
      valid: true,
      name: '',
      description: '',
      fields: [
        {
          key: 'fieldCode',
          label: 'Loại điều kiện',
          thStyle: { textAlign: 'center', width: '30%' },
        },
        {
          key: 'operator',
          label: 'Toán tử',
          thStyle: { textAlign: 'center', width: '30%' },
        },
        {
          key: 'value',
          label: 'Giá trị',
          thStyle: { textAlign: 'center', width: '30%' },
        },
        {
          key: 'actions',
          label: '',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '5%',
          },
        },
      ],
    };
  },
  validations: {
    name: { required },
    description: { minLength: minLength(1) },
    settings: { required, minLength: minLength(1), maxLength: maxLength(5) },
  },
  components: {
    KTCodePreview,
    Treeselect,
    // datePicker,
    // Autosuggest,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Danh sách loại khách hàng', route: '/customer-type' },
      { title: 'Thêm loại khách hàng' },
    ]);
  },
  created() {
    this.fetchFieldCode();
    this.fetchOperator();
  },
  methods: {
    deleteField(itemSelected) {
      this.settings = this.settings.filter(
        (item) => item.id !== itemSelected.id,
      );
    },
    newId() {
      return v4();
    },
    format(value) {
      this.birthday = value;
      return value;
    },
    insertField() {
      const item = {
        id: this.newId(),
        fieldCode: '',
        operator: '',
        logicOperator: 'AND',
        value: '',
      };
      this.settings.push(item);
    },
    fetchFieldCode: async function() {
      ApiService.query(`/rfm/list-field-code`).then((response) => {
        const data = response.data.data;
        for (const item of data) {
          this.listField.push(item);
        }
      });
    },
    fetchOperator: async function() {
      ApiService.query(`/rfm/list-operator`).then((response) => {
        const data = response.data.data;
        for (const item of data) {
          this.listOperator.push(item);
        }
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    createSegment: async function() {
      this.isNew = false;
      this.$v.name.$touch();
      this.$v.settings.$touch();
      if (this.$v.name.$anyError) {
        return;
      }
      if (this.$v.settings.$anyError) {
        this.makeToastFaile('Vui lòng cấu hình điều kiện cho segment');
        return;
      }

      const _setting = this.settings.map((item) => {
        delete item.id;
        if (item.fieldCode === SEGMENT_FIELD_CODE.BILL_CREATE_DATE) {
          item['value'] = moment(item.value, 'DD/MM/YYYY').format('yyyy-MM-DD');
        } else {
          item['value'] = +item.value.replace(/,/g, '');
        }
        return item;
      });

      const data = {
        name: this.name,
        description: this.description,
        settings: _setting,
      };
      ApiService.post('rfm/create', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.$router.push({
              path: '/customer-segment',
            });
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch((error) => {
          console.log('error: ', error);
          this.makeToastFaile('Thêm segment khách hàng bị lỗi!!!!');
        });
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    fetchEmployee: async function() {
      this.optionEmployee = [];
      ApiService.setHeader();
      ApiService.get('/employees/get-list-filter').then((response) => {
        const employees = response.data.data;
        employees.map((element) => {
          let suggestionName = element.name;
          if (element.code) {
            suggestionName += ' ( ' + element.code + ')';
          }
          let nameNoneSign = xoa_dau(element.name);
          let store = {
            id: element.id,
            name: element.name,
            code: element.code,
            suggestionName: suggestionName,
            nameNoneSign: nameNoneSign,
          };
          this.optionEmployee.push(store);
        });
        this.filteredOptionEmployee = [...this.optionEmployee];
      });
    },
    fetchCity() {
      ApiService.setHeader();
      ApiService.get('city/').then((data) => {
        this.listCity = data.data.data;
      });
    },
    getDistrict() {
      this.selectedDistrict = null;
      this.selectWard = null;
      ApiService.setHeader();
      ApiService.get(`city/${this.selectedCity}/district`).then((data) => {
        this.listDistrict = data.data.data;
      });
    },
    getWard() {
      this.selectWard = null;
      ApiService.setHeader();
      ApiService.get(`city/${this.selectedDistrict}/ward`).then((data) => {
        this.listWard = data.data.data;
      });
    },
    onContext(ctx) {
      if (ctx.selectedYMD == '') {
        this.birthday = '';
      } else {
        this.birthday = ctx.selectedYMD;
      }
    },
    onSelectedEmployee(option) {
      this.searchEmployee = option.item.name;
      this.selectedEmployee = option.item.id;
    },
    onInputChangeEmployee(text) {
      if (!text) {
        text = '';
      }
      this.searchEmployee = text;

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.optionEmployee
        .filter((item) => {
          return (
            item.nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptionEmployee = [...filteredData];
    },
  },
};
</script>
